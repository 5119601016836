<template>
    <div>


<ErrorAlert :msg="errorMgs" :visible="ShowError" />
 <PostsSkeleton v-if="!showContent" />
    <b-container v-if="showContent" class="mt-5 "  >
      <p v-if="posts.length == 0">{{$t('No data found!')}}</p>
       <b-row >
       <b-col  cols="12" lg="3" xl="3" md="6"  v-for="post in posts" :key="'competitor'+ post.id" >
       
        <b-card 
            :title="post.name"
            :img-src="'https://img.youtube.com/vi/'+getIdFromUTuberl(post.url)+'/0.jpg'"
            img-alt="Image"
            img-top
            tag="article"
            style="max-width: 20rem;"
            class="mb-2"
        >
      

        <b-button :to="{ name: 'Competitor_show',params: { id: post.id }  }" variant="primary">المزيد</b-button>
        </b-card>


     
      </b-col>
      </b-row>

  </b-container>
</div>
</template>

<script>

import axios from 'axios'

import ErrorAlert from '@/components/alerts/ErrorAlert.vue'
import PostsSkeleton from '@/components/skeletons/PostsSkeleton.vue'
import {getIdFromUrl } from 'vue-youtube'
export default {
    components:{
        ErrorAlert,PostsSkeleton
    },
    /*********************** */
    data() {
      return {
      
    
        posts:[],
        path:this.$store.state.backendUrl+"api/ar/competitors/"+this.$route.params.slug,
        
        file_url:null,
        
        errorsBack:{},
        current_page:null,
        last_page:null ,
        first_page_url:"",
        last_page_url:"",
        next_page_url:"",
        prev_page_url:"",
        per_page:null,
        total:null,

        showContent: false,
        isSendingForm:false,
        errorMgs:'',
        ShowError: false,
        
     
      }
    },
    /******************* */
 
 
    methods: {
      getIdFromUTuberl(url){
       return  getIdFromUrl(url)
      },
       fixingOption(json){
          var array=[];
          for (let index = 0; index < json.length; index++) {
            array[index] ={"text":this.$t(json[index].name),"value":json[index].name} ;
            
          }
           return array;
        },
         getCreateData(){
           this.showTable= false
           axios.get(this.path, {params:this.form,
            headers: {
           'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
                console.log(response);
                
           
                this.posts = response.data.success.posts.data;
                this.showContent = true;
         
            })
            .catch( (error) => {
                console.log(error);
                this.ShowError=true;
                this.errorMgs = error;
                
            });
           
      } , 
   
   
    },
    /***************** */
     mounted() {
      this.getCreateData();
      //this.form.lang_id = this.$i18n.locale;
      if(this.$i18n.locale == 'ar') {this.localeDate ='ar-EG'}
    }
    
}
</script>